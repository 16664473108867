<template>
  <!-- CONSENT FORM -->
  <div id="CompConsentForm" class="Content-Page">
    <div class="box-S4">
      <div class="B-carddetail B-formMain">
        <div class="b-checkbox checkin">
          <p class="highlight">CONSENT FORM</p>
          <p>
            I would like to give my consent to United Offshore Aviation Co.,
            Ltd. <span class="highlight">(“UOA”),</span> to collect, use,
            disclose, transfer and process my following general personal data
            <span class="highlight">(“General Personal Data”)</span> and
            sensitive personal data
            <span class="highlight">(“Sensitive Personal Data”)</span>
            (collectively referred to as
            <span class="highlight">“Personal Data”)</span> for the purposes of
          </p>
          <div style="padding-left: 40px">
            •
            <span style="padding-left: 10px"
              >Arranging the helicopter Service Business</span
            >
          </div>
          <div style="padding-left: 40px">
            •
            <span style="padding-left: 10px"
              >Arranging the helicopter service safety measurement</span
            >
          </div>
          <div style="padding-left: 40px">
            •
            <span style="padding-left: 10px"
              >Sharing Personal Data within your employer</span
            >
          </div>
          <p></p>
          <table>
            <tr>
              <th>General Personal Data</th>
              <th>Sensitive Personal Data</th>
            </tr>
            <tr>
              <td>
                • Name and surname<br />• Identification Card No or Passport
                No.<br />• Nationality<br />• Contact numbers<br />• Body Weight
              </td>
              <td>
                • Alcohol Test<br />• Temperature Test<br /><br /><br />
                <p></p>
              </td>
            </tr>
          </table>
          <p></p>
          <div class="highlight">Disclosure or transfer of Personal Data</div>
          <p>
            UOA might be required to disclose and transfer my Personal Data for
            the above purposes to the employers of Personal Data's owner or
            governmental parties including the employer of Personal Data's owner
            and the competent authority.
          </p>
          <p>
            Details about how UOA collects, uses, discloses, transfers and
            processes my Personal Data can be found in the
            <span class="highlight">Privacy Notice</span>
            which I read before.
          </p>
          <p>
            By inserting a check mark in the box below, I fully understand the
            content and the purposes that UOA stated herein for the collection,
            use, disclosure, transfer and processing of my Personal Data.
          </p>

          <div v-show="generalPersonalDataIsShow">
            <p class="highlight">General Personal data</p>
            <p></p>
            <v-radio-group
              :disabled="generalPersonalDataDisabled"
              v-model="generalPersonalData"
              v-on:change="OpenDialogDoNotGiveGeneralPersonalData()"
            >
              <v-radio value="false">
                <template v-slot:label>
                  <div>
                    I
                    <span style="font-weight: bold; text-decoration: underline"
                      >do not give</span
                    >
                    my consent to UOA for the collection, use, disclosure,
                    transfer and processing of my
                    <span style="font-weight: bold"
                      >General Personal Data.</span
                    >
                  </div>
                </template>
              </v-radio>
              <v-radio value="true">
                <template v-slot:label>
                  <div>
                    I
                    <span style="font-weight: bold; text-decoration: underline"
                      >do give</span
                    >
                    my consent to UOA for the collection, use, disclosure,
                    transfer and processing of my
                    <span style="font-weight: bold"
                      >General Personal Data.</span
                    >
                  </div>
                </template>
              </v-radio>
            </v-radio-group>

            <!-- Signature Pad General Personal Data-->
            <div
              v-show="!generalPersonalDataDisabled"
              v-if="generalPersonalData != null"
            >
              <div class="N-Page">
                <div class>
                  <span class="T-size-16">Signature</span>
                </div>
              </div>
              <p></p>
              <div class="b-signature">
                <div class="b-sign">
                  <!-- ใส่ Script Sign -->
                  <VueSignaturePad
                    width="100%"
                    height="500px"
                    ref="signaturePad"
                    :options="options"
                    v-show="!generalPersonalDataDisabled"
                  />
                </div>
              </div>
              <div class="center" v-if="generalPersonalData != null">
                <p>
                  Name :
                  {{ user.u_firstname + "  " + user.u_lastname }}
                </p>
                <!-- <p>Date Time : {{ dateTimeNow | yyyyMMddHHmmAdd7Hour }}</p> -->
              </div>
              <div
                class="box-S4 flex-between-center"
                v-if="generalPersonalData != null"
              >
                <div class="b-btnSave">
                  <v-btn
                    class="theme-btn-even btn-cancle Bsize150"
                    @click="UndoSignaturePersonalData()"
                  >
                    <span class="T-size-18">Clear</span>
                  </v-btn>
                  <v-btn
                    class="theme-btn-even btn-sign Bsize150"
                    @click="SaveSignatureGeneralPersonalDataToDB()"
                  >
                    <span class="I-sign2"></span>
                    <span class="T-size-18">Sign</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <!-- Signature Pad General Personal Data-->

          <!--Show Img Signature -->
          <div class="box-S4" v-show="generalPersonalDataDisabled">
            <div class="B-carddetail B-formMain">
              <div class="b-condition">
                <v-img
                  class="center"
                  max-width="500px"
                  :src="generalPersonalDataSignature"
                ></v-img>
              </div>
              <div style="text-align: center">
                <p>
                  Name :
                  {{ user.u_firstname + "  " + user.u_lastname }}
                </p>
                <p>{{ generalPersonalDataTime | yyyyMMddHHmmAdd7Hour }}</p>
              </div>
            </div>
          </div>
          <!--Show Img Signature -->

          <p></p>

          <div v-show="sensitivePersonalDataIsShow">
            <p class="highlight">Sensitive Personal Data</p>
            <p></p>
            <v-radio-group
              :disabled="sensitivePersonalDataDisabled"
              v-model="sensitivePersonalData"
              v-on:change="OpenDialogDoNotGiveSensitivePersonalData()"
            >
              <v-radio value="false">
                <template v-slot:label>
                  <div>
                    I
                    <span style="font-weight: bold; text-decoration: underline"
                      >do not give</span
                    >
                    my explicit consent to UOA for the collection, use,
                    disclosure, transfer and processing of my
                    <span style="font-weight: bold"
                      >Sensitive Personal Data.</span
                    >
                  </div>
                </template>
              </v-radio>
              <v-radio value="true">
                <template v-slot:label>
                  <div>
                    I
                    <span style="font-weight: bold; text-decoration: underline"
                      >do give</span
                    >
                    my explicit consent to UOA for the collection, use,
                    disclosure, transfer and processing of my
                    <span style="font-weight: bold"
                      >Sensitive Personal Data.</span
                    >
                  </div>
                </template>
              </v-radio>
            </v-radio-group>

            <!-- Signature Pad Sensitive Personal Data-->
            <div
              v-show="!sensitivePersonalDataDisabled"
              v-if="sensitivePersonalData != null"
            >
              <div class="N-Page">
                <div class>
                  <span class="T-size-16">Signature</span>
                </div>
              </div>
              <p></p>
              <div class="b-signature">
                <div class="b-sign">
                  <VueSignaturePad
                    width="100%"
                    height="500px"
                    ref="signaturePad2"
                    :options="options2"
                  />
                </div>
              </div>
              <div class="center" v-if="sensitivePersonalData != null">
                <p>Name : {{ user.u_firstname + "  " + user.u_lastname }}</p>
                <!-- <p>Date Time : {{ dateTimeNow | yyyyMMddHHmmAdd7Hour }}</p> -->
              </div>
              <div
                class="box-S4 flex-between-center"
                v-if="sensitivePersonalData != null"
              >
                <div class="b-btnSave">
                  <v-btn
                    class="theme-btn-even btn-cancle Bsize150"
                    @click="UndoSignatureSensitivePersonalData()"
                  >
                    <span class="T-size-18">Clear</span>
                  </v-btn>
                  <v-btn
                    class="theme-btn-even btn-sign Bsize150"
                    @click="SaveSignatureSensitivePersonalDataToDB()"
                  >
                    <span class="I-sign2"></span>
                    <span class="T-size-18">Sign</span>
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- Signature Pad Sensitive Personal Data-->

            <!--Show Img Signature -->
            <div class="box-S4" v-show="sensitivePersonalDataDisabled">
              <div class="B-carddetail B-formMain">
                <div class="b-condition">
                  <v-img
                    class="center"
                    max-width="500px"
                    :src="sensitivePersonalDataSignature"
                  ></v-img>
                </div>
                <div style="text-align: center">
                  <p>
                    Name :
                    {{ user.u_firstname + "  " + user.u_lastname }}
                  </p>
                  <p>
                    Date Time :
                    {{ sensitivePersonalDataTime | yyyyMMddHHmmAdd7Hour }}
                  </p>
                </div>
              </div>
            </div>
            <!--Show Img Signature -->
          </div>
        </div>
      </div>
    </div>

    <!-- General Personal Data -->
    <v-dialog
      v-model="dialogDoNotGiveGeneralPersonalData"
      persistent
      max-width="300"
    >
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog warning3">
            <div class="t-title" style="font-size: 16px">
              ถ้าคุณไม่ยินยอมให้ข้อมูลส่วนบุคคล<br />คุณอาจจะไม่ได้รับบริการเฮลิคอปเตอร์<br />
              <span style="font-size: 14px"
                >If you do not consent to the provision of personal information,
                you probably<br />won't get a helicopter service.
              </span>
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-save Bsize150"
              texts
              @click="CloseDialogDoNotGiveGeneralPersonalData()"
            >
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- General Personal Data -->

    <!-- General Personal Data -->
    <v-dialog
      v-model="dialogDoNotGiveSensitivePersonalData"
      persistent
      max-width="300"
    >
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog warning3">
            <div class="t-title" style="font-size: 16px">
              ถ้าคุณไม่ยินยอมให้ข้อมูลส่วนบุคคล<br />คุณอาจจะไม่ได้รับบริการเฮลิคอปเตอร์<br />
              <span style="font-size: 14px"
                >If you do not consent to the provision of personal information,
                you probably<br />won't get a helicopter service.
              </span>
            </div>
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-save Bsize150"
              texts
              @click="CloseDialogDoNotGiveSensitivePersonalData()"
            >
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- General Personal Data -->

    <div class="box-btn" style="text-align: center">
      <v-btn
        class="theme-btn-even btn-save Bsize100"
        text
        @click="GotoPersonal()"
        v-show="
          this.user.u_consent_general_personal_data != null &&
          this.user.u_consent_sensitive_personal_data != null
        "
      >
        <span class="T-size-18">Finish</span>
      </v-btn>
    </div>
  </div>
  <!-- CONSENT FORM -->
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
import uploadBlob from "../../utils/uploadBlob";

import fillerdate from "../../utils/filterdate";
import { differenceInDays, format, addHours } from "date-fns";

export default {
  data: () => ({
    userdata: "",
    generalPersonalDataIsShow: false,
    generalPersonalDataDisabled: false,
    generalPersonalDataSignature: "",
    generalPersonalDataTime: "",
    generalPersonalData: null,
    dialogDoNotGiveGeneralPersonalData: false,

    sensitivePersonalDataIsShow: false,
    sensitivePersonalDataDisabled: false,
    sensitivePersonalDataSignature: "",
    sensitivePersonalDataTime: "",
    sensitivePersonalData: null,
    dialogDoNotGiveSensitivePersonalData: false,

    dateTimeNow: new Date().toISOString(),
    options: {
      penColor: "#00f",
    },
    options2: {
      penColor: "#00f",
    },
    user: {},
  }),
  props: ["userId", "passId", "owner", "destination","o_id","f_id"],
  components: {},
  async mounted() {
    this.userdata = await JSON.parse(localStorage.getItem("user"));
    this.RenderUI();
  },
  computed: {},
  methods: {
    async RenderUI() {
      this.user = await feathersClientUOA.service("user").get(this.userId);

      if (this.user.u_consent_general_personal_data == null) {
        this.generalPersonalDataIsShow = true;
      }else{
              this.generalPersonalData = this.user.u_consent_general_personal_data.toString();
      }

      if (this.user.u_consent_sensitive_personal_data == null) {
        this.sensitivePersonalDataIsShow = true;
      }else{
          this.sensitivePersonalData = this.user.u_consent_sensitive_personal_data.toString();
      }
    },
    async UndoSignaturePersonalData() {
      this.$refs.signaturePad.undoSignature();
    },
    async SaveSignatureGeneralPersonalDataToDB() {
      if (this.generalPersonalData == "true") {
        const dataURL = this.$refs.signaturePad.saveSignature();
        const resSignature = await uploadBlob(dataURL.data);
        try {
          let dataConsnet = {
            u_consent_general_personal_data: this.generalPersonalData,
            u_consent_general_personal_data_signature: resSignature.data.Url,
            u_consent_general_personal_data_time: new Date(),
            u_consent_general_personal_data_version: 1,
          };
          var res = await feathersClientUOA
            .service("user")
            .patch(this.userId, dataConsnet);
          this.generalPersonalDataSignature =
            res.u_consent_general_personal_data_signature;
          this.generalPersonalDataTime =
            res.u_consent_general_personal_data_time;
          this.generalPersonalDataDisabled = true;
          this.RenderUI();
          //SEQ LOG
          let properties = {
            f_id: parseInt(this.f_id),
            userId: parseInt(this.userId)
          };
          this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, userId={userId}', { action: "ConsentFormPersonalData", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
        } catch (error) {
          //SEQ LOG
          this.$log.Error("ConsentFormPersonalData, Error : {error}", { error });
          console.log("ConsentFormPersonalData, Error : {error}" + error);
        }
      } else {
        this.OpenDialogDoNotGiveGeneralPersonalData();
      }
    },

    async UndoSignatureSensitivePersonalData() {
      this.$refs.signaturePad2.undoSignature();
    },
    async SaveSignatureSensitivePersonalDataToDB() {
      if (this.sensitivePersonalData == "true") {
        const dataURL = this.$refs.signaturePad2.saveSignature();
        const resSignature = await uploadBlob(dataURL.data);
        try {
          let dataConsnet = {
            u_consent_sensitive_personal_data: this.sensitivePersonalData,
            u_consent_sensitive_personal_data_signature: resSignature.data.Url,
            u_consent_sensitive_personal_data_time: new Date(),
            u_consent_sensitive_personal_data_version: 1,
          };
          var res = await feathersClientUOA
            .service("user")
            .patch(this.userId, dataConsnet);
          this.sensitivePersonalDataSignature =
            res.u_consent_sensitive_personal_data_signature;
          this.sensitivePersonalDataTime =
            res.u_consent_sensitive_personal_data_time;
          this.sensitivePersonalDataDisabled = true;
          this.RenderUI();
           //SEQ LOG
           let properties = {
            f_id: parseInt(this.f_id),
            userId: parseInt(this.userId)
          };
          this.$log.Info('{action} by {username} ({role}) : f_id={f_id}, userId={userId}', { action: "ConsentFormSensitivePersonalData", username: this.userdata.u_username, role: this.userdata.r_name, ...properties });
        } catch (error) {
          //SEQ LOG
          this.$log.Error("ConsentFormSensitivePersonalData, Error : {error}", { error });
          console.log("ConsentFormSensitivePersonalData, Error : {error}" + error);
        }
      } else {
        this.OpenDialogDoNotGiveSensitivePersonalData();
      }
    },
    OpenDialogDoNotGiveGeneralPersonalData() {
      if (this.generalPersonalData == "false") {
        this.dialogDoNotGiveGeneralPersonalData = true;
      }
    },

    CloseDialogDoNotGiveGeneralPersonalData() {
      this.dialogDoNotGiveGeneralPersonalData = false;
      //this.generalPersonalData = null;
    },

    OpenDialogDoNotGiveSensitivePersonalData() {
      if (this.sensitivePersonalData == "false") {
        this.dialogDoNotGiveSensitivePersonalData = true;
      }
    },

    CloseDialogDoNotGiveSensitivePersonalData() {
      this.dialogDoNotGiveSensitivePersonalData = false;
      // this.sensitivePersonalData = null;
    },

    async GotoPersonal() {
      this.$router.push({
        path: `/personalcheckin/${this.passId}/${this.owner}/${this.destination}/false/${this.o_id}/${this.f_id}`,
      });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

table,
td,
th {
  border: 2px solid white;
  padding-left: 10px;
}

.highlight {
  font-weight: bold;
  display: inline;
}
</style>>

