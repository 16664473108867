<template>
  <div id="flightDetail" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <!-- <div class="N-Page T-size-36">{{ mode }} Flight</div> -->
        <!-- Edit -->
        <div class="N-Page T-size-36">CONSENT FORM</div>
      </div>
    </div>

    <div id="ownerContent" class="wrap-Main">
      <div class="box-S4 flex-between-center noPadding">
        <div class="box-S4">
          <div class="B-carddetail">
            <ConsentForm
              :userId="$route.params.id"
              :passId="$route.params.idPass"
              :owner="$route.params.o_name"
              :o_id="$route.params.o_id"
              :destination="$route.params.destination"
              :f_id="$route.params.idflight"
            ></ConsentForm>
          </div>
        </div>
      </div>
      <div class="line marginTop"></div>
    </div>

    <!-- PrivacyNotice -->
    <v-dialog
      v-model="dialogPrivacyNotice"
      persistent
      max-width="800"
      fullscreen
    >
      <PrivacyNotice
        :userId="$route.params.id"
        :idflight="$route.params.idflight"
        @PrivacyNotice="ResponsePrivacyNotice"
      ></PrivacyNotice>
    </v-dialog>
    <!-- PrivacyNotice -->
  </div>
</template>

<script>
import fillerdate from "../utils/filterdate";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import PrivacyNotice from "../components/pdpa/CompPrivacyNotice.vue";
import ConsentForm from "../components/pdpa/CompConsentForm.vue";

export default {
  data: () => ({
    userId: null,
    passId: null,
    owner: null,
    o_id: null,
    destination: null,
    idflight:null,
    dialogPrivacyNotice: false,
  }),
  props: [],
  components: {
    PrivacyNotice,
    ConsentForm,
  },
  async mounted() {
    this.userId = this.$route.params.id;
    this.passId = this.$route.params.idPass;
    this.owner = this.$route.params.o_name;
    this.o_id = this.$route.params.o_id;
    this.destination = this.$route.params.destination;
    this.idflight = this.$route.params.f_id;

    var resUser = await feathersClientUOA.service("user").get(this.userId);

    if (resUser.u_privacy_notice == null) {
      this.dialogPrivacyNotice = true;
    } else {
      this.dialogPrivacyNotice = false;
    }

    if (
      resUser.u_privacy_notice != null &&
      resUser.u_consent_general_personal_data != null &&
      resUser.u_consent_sensitive_personal_data != null
    ) {
      this.GotoPersonal();
    }
  },
  computed: {},
  methods: {
    ResponsePrivacyNotice() {
      this.dialogPrivacyNotice = false;
    },
    async GotoPersonal() {
      this.$router.push({
        path: `/personalcheckin/${this.passId}/${this.owner}/${this.destination}/false/${this.o_id}/${this.idflight}`,
      });
    },
  },
};
</script>
<style scoped>
.mdi:before,
.mdi-set {
  color: #33cc33;
}
</style>
<style>
.v-messages__message {
  color: #b0afaf !important;
}
</style>